require('./bootstrap');
require('select2');
require('jquery.redirect');

import '@chenfengyuan/datepicker';
import 'daterangepicker';
import 'bootstrap-select';
import 'slick-slider';
import moment from 'moment';
import Swal from 'sweetalert2';

window.Swal = Swal;
window.moment = moment;
let lastScrollTop = 0;
let top = 60;

$(document).ready(() => {
    $(".select2-global").select2({
        width: '100%'
    });
    $('.selectpicker').selectpicker();
    let navbar_height = document.querySelector('.fixed-top').offsetHeight;
    document.body.style.paddingTop = navbar_height + 'px';
    window.addEventListener('scroll', function () {
        if (document.querySelector('.fixed-top')) {
            navbar_height = document.querySelector('.fixed-top').offsetHeight;
        }
        const pre_navbar_height = document.querySelector('div.navbar-expand').offsetHeight
        const st = window.scrollY;
        if (window.innerWidth > 800) {
            if ($(window).scrollTop() >= top) {
                $('#navbar_top').addClass('fixed-top');
                document.body.style.paddingTop = navbar_height + 60 + 'px';
            } else {
                $('#navbar_top').removeClass('fixed-top');
                document.body.style.paddingTop = '60px';
            }
        } else {
            $('#navbar_top').addClass('fixed-top');
            document.body.style.paddingTop = navbar_height + 'px';
        }
        if (st <= lastScrollTop) {
            $('#navbar_top').css('top', 0);
        } else if (st > navbar_height) {
            $('#navbar_top').css('top', (pre_navbar_height * -1) + 'px');
        }
        lastScrollTop = st;
    });
    window.addEventListener('load', function () {
        if (document.body.scrollTop === 0 && window.innerWidth > 800) {
            $('#navbar_top').removeClass('fixed-top');
            document.body.style.paddingTop = '60px';
        }
    })

    $('.fa-search').click(function (e) {
        e.preventDefault();
        const $button = $(this);
        if (!$button.hasClass('active')) {
            $button.addClass('active');
            $('.search-input').show();
            return false;
        } else {
            $button.closest('form').submit();
        }
    });
    $('#top-slider').slick({
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: '.next',
        prevArrow: '.prev',
        dots: false,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    dots: true,
                    prevArrow: null,
                    nextArrow: null,
                }
            }
        ]
    });
    $('#magazines-slider').slick({
        speed: 400,
        slidesToShow: $('#magazines-slider').find('.slide').length < 5 ? $('#magazines-slider').find('.slide').length : 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        // nextArrow: '.nextMagazine',
        // prevArrow: '.prevMagazine',
        dots: false,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    dots: true,
                    prevArrow: null,
                    nextArrow: null,
                }
            }
        ]
    });
    $(document).on('click touchend', function (e) {
        if ($('.navbar-toggler:not(.collapsed)').length && $(e.target).closest("#navbar_top").length === 0) {
            $('.navbar-toggler').addClass('collapsed');
            $('.navbar-collapse').removeClass('show');
            document.body.style.paddingTop = '60px';
        }
    });
    $('.dropdown-menu a').on('click touchend', function (e) {
        if ($(this).attr('href') === '#')
            e.preventDefault();
        if ($(window).width() < 992) {
            if ($(this).siblings('.submenu').length) {
                $(this).siblings('.submenu').toggleClass('show', !$(this).siblings('.submenu').is(':visible'));
            }
            $('.dropdown').on('hide.bs.dropdown', function () {
                $(this).find('.submenu').removeClass('show');
            })
        }
    });
});
